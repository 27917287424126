import { useTranslations } from 'next-intl';
import Image from 'next/image';

import { Button } from '@/components/atoms';
import { LINKS, getLink } from '@/utils/getLink';

type TWhyWaitProps = {
  description: string | React.ReactNode;
  hasExpertButton?: boolean;
};
function WhyWait({ description, hasExpertButton }: Readonly<TWhyWaitProps>) {
  const t = useTranslations();
  return (
    <section className="w-full bg-bg-weak">
      <div className="container">
        <div className="flex flex-col gap-[41px] pt-10 lg:flex-row lg:pt-0">
          <div className="flex min-w-full flex-1 flex-col justify-center gap-8 sm:min-w-[460px] lg:mt-[56px] lg:mb-[56px] lg:gap-12">
            <div className="flex flex-col gap-4">
              <h3 className="display-large">{t('whyWait')}</h3>
              {description}
            </div>

            <div className="flex flex-col gap-4 lg:flex-row">
              <Button
                href={LINKS.signUp}
                color="secondary"
                size="lg"
                className="w-full lg:w-fit"
                aria-label={t('createAFreeAccount')}
                as="a"
              >
                {t('createAFreeAccount')}
              </Button>
              {hasExpertButton && (
                <Button
                  as="a"
                  href={getLink('contactUs')}
                  className="w-full lg:w-fit"
                  size="lg"
                  color="white"
                  aria-label={t('talkToAnExpert')}
                >
                  {t('talkToAnExpert')}
                </Button>
              )}
            </div>
          </div>
          <div className="hidden flex-grow justify-end lg:flex">
            <Image
              className="w-full object-contain object-bottom"
              src="/images/pages/home/why-wait.webp"
              width="1320"
              height="860"
              alt="why wait image"
              loading="lazy"
            />
          </div>
          <Image
            className="w-full object-contain lg:hidden"
            src="/images/pages/home/why-wait-mobile.webp"
            width="1320"
            height="860"
            alt="why wait mobile image"
            loading="lazy"
          />
        </div>
      </div>
    </section>
  );
}

export { WhyWait };
