import qs from 'qs';

import { ENV } from '@/config/env';

type ApiConfig = {
  baseURL: string;
  defaultHeaders?: HeadersInit;
};

type RequestOptions<TBody = unknown> = {
  params?: Record<string, unknown>;
  headers?: HeadersInit;
  body?: TBody;
};

export function createApi({ baseURL, defaultHeaders = {} }: ApiConfig) {
  // a function to serialize the parameters
  const serializeParams = (params: Record<string, unknown>) => {
    return qs.stringify(params, { arrayFormat: 'brackets' });
  };

  // Fetch wrapper with type safety
  const http = {
    get: async <TResponse>(endpoint: string, options?: RequestOptions & RequestInit) => {
      return makeRequest<TResponse>('GET', endpoint, options);
    },

    post: async <TResponse, TBody = unknown>(
      endpoint: string,
      options?: RequestOptions<TBody> & RequestInit
    ) => {
      return makeRequest<TResponse>('POST', endpoint, options);
    },

    put: async <TResponse, TBody = unknown>(
      endpoint: string,
      options?: RequestOptions<TBody> & RequestInit
    ) => {
      return makeRequest<TResponse>('PUT', endpoint, options);
    },

    delete: async <TResponse>(endpoint: string, options?: RequestOptions & RequestInit) => {
      return makeRequest<TResponse>('DELETE', endpoint, options);
    },
  };

  const makeRequest = async <TResponse>(
    method: string,
    endpoint: string,
    options?: RequestOptions & RequestInit
  ): Promise<TResponse> => {
    try {
      // create url
      const url = new URL(
        `${baseURL}${endpoint}${options?.params ? `?${serializeParams(options.params)}` : ''}`
      );

      // merge headers
      const headers = {
        'Content-Type': 'application/json',
        'X-Access-Token': ENV.SECRET_KEY as string,
        'x-build-version': ENV.BUILD_VERSION as string,
        ...defaultHeaders,
        ...options?.headers,
      };

      // stringify body
      const body = options?.body ? JSON.stringify(options.body) : undefined;

      const response = await fetch(url.toString(), {
        method,
        headers,
        body,
        ...(options?.next ? { next: options.next } : {}),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      return (await response.json()) as TResponse;
    } catch (error) {
      console.error('API request failed:', error);
      throw error;
    }
  };

  return http;
}
