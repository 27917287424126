import { Button, Icon } from '@/components/atoms';
import { COMPANY_INFO } from '@/constants/companyInfo';
import { cn } from '@/lib/utils';
import { LINKS } from '@/utils/getLink';
import { useTranslations } from 'next-intl';

const amounts = [
  {
    title: `${COMPANY_INFO.totalGifts}`,
    subTitle: 'Gifts a year',
    icon: 'medal',
    cn: 'bg-light-blue-100',
  },
  {
    title: COMPANY_INFO.totalBrands,
    subTitle: 'Brands including 1-800-Flowers.com, Harry & David, Cheryl’s Cookies, and more',
    icon: 'confetti',
    cn: 'bg-yellow-100',
  },
  {
    title: `${COMPANY_INFO.totalGiftsMothersDay}`,
    subTitle: 'Stems delivered every Mothers’ Day',
    icon: 'gift-box',
    cn: 'bg-green-25',
  },
  {
    title: 'FLWS',
    subTitle: 'We are a public company',
    icon: 'shopping-bag',
    cn: 'bg-purple-50',
  },
  {
    title: COMPANY_INFO.totalYears,
    subTitle: 'Years in business and still founder led',
    icon: 'history',
    cn: 'bg-red-25',
  },
  {
    title: `${COMPANY_INFO.totalCustomers}`,
    subTitle: 'Business customers, including Fortune 500 companies',
    icon: 'star',
    cn: 'bg-blue-25',
  },
];

function Amounts() {
  const t = useTranslations();
  return (
    <section className="w-full bg-bg-body">
      <div className="container py-10 pb-20">
        <div className="flex min-w-full flex-1 flex-col items-center justify-center gap-10 sm:min-w-[460px]">
          <h1 className="h1 text-center">{t('enterprisesAmountsSectionTitle')}</h1>
          <div className="grid grid-cols-1 gap-10 lg:grid-cols-3">
            {amounts.map((amount) => (
              <div
                key={amount?.title}
                className={cn(
                  'relative flex flex-col items-center rounded-lg px-4 pt-10 pb-6',
                  amount.cn
                )}
              >
                <div className="-top-8 absolute flex h-16 w-16 items-center justify-center rounded-full bg-bg-body shadow-md">
                  <Icon name={amount.icon} />
                </div>
                <h1 className="display-medium text-center">{amount.title}</h1>
                <h1 className="body1 text-center text-fg-weak">{amount.subTitle}</h1>
              </div>
            ))}
          </div>

          <Button
            aria-label="Visit learn more page"
            target="_blank"
            href={LINKS.amountsLearnMore}
            as={'a'}
            color="primary"
            size="lg"
            className="w-full lg:w-fit"
          >
            {t('learnMore')}
          </Button>
        </div>
      </div>
    </section>
  );
}

export { Amounts };
