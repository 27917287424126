import { Button, Icon } from '@/components/atoms';
import { LINKS } from '@/utils/getLink';
import { useTranslations } from 'next-intl';
import Image from 'next/image';

function renderFeatureKeyTextB(chunks: React.ReactNode) {
  return <b>{chunks}</b>;
}

function ServiceFeatures() {
  const t = useTranslations();

  return (
    <section className="w-full bg-bg-weak">
      <div className="container py-10">
        <div className="flex flex-col items-start gap-6 sm:gap-10 lg:flex-row">
          <div className="order-2 flex w-full flex-col gap-8 lg:order-1 lg:w-[460px] lg:min-w-[460px]">
            <div className="flex flex-col gap-2 sm:gap-4">
              <h3 className="h1">{t('serviceFeaturesTitle')}</h3>
              <p className="subtitle1-semibold">{t('serviceFeaturesSubtitle')}</p>
            </div>
            <ul className="flex flex-col gap-2 sm:gap-6">
              {serviceFeaturesData.map((feature) => {
                const featureKeyText = t.rich(feature.key, {
                  b: renderFeatureKeyTextB,
                });

                return (
                  <li key={feature.icon} className="flex items-center">
                    <div className="mr-2 flex min-h-[2.5rem] min-w-[2.5rem] items-center justify-center rounded-full border bg-bg-default">
                      <Icon name={feature.icon} size="sm" />
                    </div>
                    <p>{featureKeyText}</p>
                  </li>
                );
              })}
            </ul>

            <Button
              aria-label={t('createAFreeAccount')}
              as={'a'}
              href={LINKS.signUp}
              className="w-full lg:ml-12 lg:w-fit"
              color="secondary"
            >
              {t('createAFreeAccount')}
            </Button>
          </div>

          <div className="order-1 flex-grow lg:order-2">
            <Image
              className="w-full object-contain lg:h-[620px] lg:w-auto"
              src="/images/pages/business/service-features.webp"
              width="660"
              height="620"
              alt="why wait mobile image"
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export { ServiceFeatures };

const serviceFeaturesData = [
  {
    icon: 'gift-history',
    key: 'serviceFeaturesOption1',
  },
  {
    icon: 'gift-box',
    key: 'serviceFeaturesOption2',
  },
  {
    icon: 'email',
    key: 'serviceFeaturesOption3',
  },
  {
    icon: 'confetti',
    key: 'serviceFeaturesOption4',
  },
  {
    icon: 'dollar',
    key: 'serviceFeaturesOption5',
  },
];
